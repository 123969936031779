import React from "react";
import { InstagramEmbed } from "react-social-media-embed";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const divStyle = {
  color: "blue",
  backgroundColor: "#F8F6F4",
};

const settings = {
  dots: true,
  arrows: false,
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 1024, // for tablets and larger devices
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 768, // for landscape phones and small tablets
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 480, // for portrait phones
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const BlogSlider = () => {
  const embedContainerStyle = {
    height: "500px", // Set the desired height for all embeds
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  };

  return (
    <div className="">
      <div
        className="wide-section-title-area wide-section-space pt-[40px] pb-[30px]"
        style={divStyle}
      >
        <div className="testimonial-shape-path-image pb-20">
          <img
            className="lg:w-[150px] w-[100px]"
            src={require("../assets/img/bg/testimonial-bg-1.webp")}
            alt="testimonial shape"
          />
        </div>
        <div className="container pb-[100px] !z-0">
          <div className="row">
            <div className="col-xxl-12">
              <Slider
                autoplay
                autoplaySpeed={3000}
                infinite={true}
                {...settings}
              >
                <div className="pr-2" style={{ position: "relative" }}>
                  <div style={embedContainerStyle}>
                    <InstagramEmbed
                      url="https://www.instagram.com/p/C56F5straKw/?img_index=1"
                      clientAccessToken="your-app-id|your-client-token"
                      maxWidth={320}
                      hideCaption={false}
                      containerTagName="div"
                      protocol=""
                      injectScript
                    />
                  </div>
                </div>

                <div className="pr-2" style={{ position: "relative" }}>
                  <div style={embedContainerStyle}>
                    <InstagramEmbed
                      url="https://www.instagram.com/p/C67xZ6WNpGC/?img_index=1"
                      clientAccessToken="your-app-id|your-client-token"
                      maxWidth={320}
                      hideCaption={false}
                      containerTagName="div"
                      protocol=""
                      injectScript
                    />
                  </div>
                </div>

                <div className="pr-2" style={{ position: "relative" }}>
                  <div style={embedContainerStyle}>
                    <InstagramEmbed
                      url="https://www.instagram.com/p/C7WyAJFxUle/?img_index=1"
                      clientAccessToken="your-app-id|your-client-token"
                      maxWidth={320}
                      hideCaption={false}
                      containerTagName="div"
                      protocol=""
                      injectScript
                    />
                  </div>
                </div>

                <div className="pr-2" style={{ position: "relative" }}>
                  <div style={embedContainerStyle}>
                    <InstagramEmbed
                      url="https://www.instagram.com/p/C7LbyNGufVy/?img_index=1"
                      clientAccessToken="your-app-id|your-client-token"
                      maxWidth={320}
                      hideCaption={false}
                      containerTagName="div"
                      protocol=""
                      injectScript
                    />
                  </div>
                </div>
                <div className="pr-2" style={{ position: "relative" }}>
                  <div style={embedContainerStyle}>
                    <InstagramEmbed
                      url="https://www.instagram.com/p/C7A0kOoLPJa/?img_index=1"
                      clientAccessToken="your-app-id|your-client-token"
                      maxWidth={320}
                      hideCaption={false}
                      containerTagName="div"
                      protocol=""
                      injectScript
                    />
                  </div>
                </div>
              </Slider>

              <div className="text-center py-20">
                <button className="text-white hover:border-2 hoverborder-red-800">
                  <a
                    className="no-underline hover:text-[#000066] border-2 hover:border-[#000066] bg-[#000066] hover:bg-white hover:rounded-2xl py-2 px-10 rounded-md"
                    href="https://www.instagram.com/trunnkinlove/"
                  >
                    View More
                  </a>
                </button>
              </div>
            </div>
          </div>
          <div className="container p-rel">
            <div className="testimonial-shape-path-image-wrap">
              <div className="testimonial-shape-path-image">
                <img
                  className="-rotate-180"
                  srcSet={require("../assets/img/bg/testimonial-bg-1.webp")}
                  alt="testimonial background"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogSlider;
