import React, { useState } from "react";
// import Button from "react-bootstrap/Button";

// img
import HeroImage1 from "../../assets/img/slider/maibanner.jpg";
import ctabg from "../../assets/img/bg/cta-bg.jpg";
import blog1 from "../../assets/img/blog/blog-1.png";
import blog2 from "../../assets/img/blog/blog-4.jpg";
import blog3 from "../../assets/img/blog/blog-3.png";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/swiper-bundle.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BlogSlider from "../../components/BlogSlider";

// Correctly import modules from Swiper

const Home = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    // Here, you can integrate your form submission logic
  };

  // if (!isOpen) return null;
  const containerStyle = {
    backgroundImage: `url(${HeroImage1})`,
    backgroundPosition: " center",
    backgroundSize: "cover",
    height: "800px",
  };

  const bgcta = {
    backgroundImage: `url(${ctabg})`,
  };
  const blogbg1 = {
    backgroundImage: `url(${blog1})`,
  };
  const blogbg2 = {
    backgroundImage: `url(${blog2})`,
  };
  const blogbg3 = {
    backgroundImage: `url(${blog3})`,
  };

  const settings = {
    infinite: true, // Infinite looping
    speed: 500, // Speed of the transition
    slidesToShow: 1, // Number of slides to show at once
    slidesToScroll: 1, // Number of slides to scroll at once
    autoplay: true, // Enables autoplay mode
    autoplaySpeed: 4000, // Delay between each auto-scroll
    cssEase: "linear", // Type of animation easing
  };

  return (
    <div className="">
      {/* 1st */}

      <div
        className="h-20 bg-cover bg-no-repeat relative -mb-[560px]  -z-[900] "
        style={containerStyle}
      >
        <div className="absolute inset-0 bg-[#0000004e] "></div>
      </div>
      <div className="mb-[20rem] -z-[909]">
        <Slider
          className=" flex justify-center text-center bg-center "
          {...settings}
        >
          <div className=" pb-20 -z-[900]">
            <h2 className="lv-single-slider-title">
              ALL WEDDING NEEDS, <br />
              <span className="text-2xl">UNDER ONE MANDAP!</span>
            </h2>
            <button className="text-white ">
              <a
                className="   no-underline hover:text-[#000066] hover:border-2 hover:border-[#000066] bg-[#000066] hover:bg-white hover:rounded-2xl py-2 px-10 rounded-md"
                href="/services"
              >
                Read More
              </a>
            </button>
          </div>
          <div className="  z-0 pb-20">
            <h2 className="lv-single-slider-title">
              GET A BUDGET, <br />
              <span className="text-2xl">AND THEN MAKE A DECISION</span>
            </h2>
            <button className="text-white">
              <a
                className="no-underline hover:text-[#000066] hover:border-2 hover:border-[#000066] bg-[#000066] hover:bg-white hover:rounded-2xl py-2 px-10 rounded-md"
                href="/contact-us"
              >
                Contact Us
              </a>
            </button>
          </div>
          <div className="  z-0 pb-20">
            <h2 className="lv-single-slider-title">
              SAY, I DO!!! <br />
              <span className="text-2xl">AND LEAVE THE WORK TO US.</span>
            </h2>
            <button className="text-white">
              <a
                className="no-underline hover:text-[#000066] hover:border-2 hover:border-[#000066] bg-[#000066] hover:bg-white hover:rounded-2xl py-2 px-10 rounded-md"
                href="/services"
              >
                Explore Services
              </a>
            </button>
          </div>
          <div className="  z-0 pb-20">
            <h2 className="lv-single-slider-title">
              ALL BLOGS <br />
              <span className="text-2xl">BLOGS</span>
            </h2>
            <button className="text-white">
              <a
                className="no-underline hover:text-[#000066] hover:border-2 hover:border-[#000066] bg-[#000066] hover:bg-white hover:rounded-2xl py-2 px-10 rounded-md"
                href="/blogs"
              >
                More
              </a>
            </button>
          </div>
        </Slider>
      </div>

      {/* <!-- slider area end -->
           <!-- about area start --> */}
      <div class="about-area pt-110   pb-xl-0 about-area-space-1 about-area-fix ">
        <div class="container">
          <div class="row">
            <div className="pb-[40px] px-2 text-center ">
              <h1 className="text-[#000066]  inline-block ">SUSWAGATAM</h1>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width=""
                height="10"
                viewBox="0 0 44 10"
              >
                <path
                  data-name="Path 49"
                  d="M-2222,968a2.5,2.5,0,0,1,2.5-2.5,2.5,2.5,0,0,1,2.5,2.5,2.5,2.5,0,0,1-2.5,2.5A2.5,2.5,0,0,1-2222,968Zm7,1v-2h17v2Zm-27,0v-2h18v2Z"
                  transform="translate(2222 -966)"
                  fill="#000066"
                ></path>
              </svg>
            </div>
            <div class="">
              <div class="lv-about-img-left pl-15 p-rel">
                <div class="lv-about-pata-img-wrap">
                  <div class="lv-about-pata-parent">
                    <div class="lv-about-pata-rotate">
                      <img
                        class="askew"
                        srcSet={require("../../assets/img/path/pata.png")}
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="lv-about-pata-parent">
                    <div class="lv-about-pata-rotate">
                      <img
                        class="askew"
                        srcSet={require("../../assets/img/path/pata.png")}
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="lv-about-pata-parent">
                    <div class="lv-about-pata-rotate">
                      <img
                        class="askew"
                        srcSet={require("../../assets/img/path/pata.png")}
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="lv-about-pata-parent">
                    <div class="lv-about-pata-rotate">
                      <img
                        class="askew"
                        srcSet={require("../../assets/img/path/pata.png")}
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="lv-about-pata-parent">
                    <div class="lv-about-pata-rotate">
                      <img
                        class="askew"
                        srcSet={require("../../assets/img/path/pata.png")}
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="lv-about-pata-parent">
                    <div class="lv-about-pata-rotate">
                      <img
                        class="askew"
                        srcSet={require("../../assets/img/path/pata.png")}
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="lv-about-pata-parent">
                    <div class="lv-about-pata-rotate">
                      <img
                        class="askew"
                        srcSet={require("../../assets/img/path/pata.png")}
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="lv-about-pata-parent">
                    <div class="lv-about-pata-rotate">
                      <img
                        class="askew"
                        srcSet={require("../../assets/img/path/pata.png")}
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="lv-about-pata-parent">
                    <div class="lv-about-pata-rotate">
                      <img
                        class="askew"
                        srcSet={require("../../assets/img/path/pata.png")}
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="lv-about-pata-parent">
                    <div class="lv-about-pata-rotate">
                      <img
                        class="askew"
                        srcSet={require("../../assets/img/path/pata.png")}
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="lv-about-pata-parent">
                    <div class="lv-about-pata-rotate">
                      <img
                        class="askew"
                        srcSet={require("../../assets/img/path/pata.png")}
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="lv-about-pata-parent">
                    <div class="lv-about-pata-rotate">
                      <img
                        class="askew"
                        srcSet={require("../../assets/img/path/pata.png")}
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="lv-about-pata-parent">
                    <div class="lv-about-pata-rotate">
                      <img
                        class="askew"
                        srcSet={require("../../assets/img/path/pata.png")}
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="lv-about-pata-parent">
                    <div class="lv-about-pata-rotate">
                      <img
                        class="askew"
                        srcSet={require("../../assets/img/path/pata.png")}
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="lv-about-pata-parent">
                    <div class="lv-about-pata-rotate">
                      <img
                        class="askew"
                        srcSet={require("../../assets/img/path/pata.png")}
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="lv-about-pata-parent">
                    <div class="lv-about-pata-rotate">
                      <img
                        class="askew"
                        srcSet={require("../../assets/img/path/pata.png")}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="flex lg:flex-row flex-col justify-center items-center gap-4">
                  <img
                    className="lg:w-[530px]"
                    srcSet={require("../../assets/img/about/about-1.jpg")}
                    alt="about image"
                  />
                  <div class="lg:w-[600px]">
                    <div class="lv-about-content-right px-2">
                      <div class=" text-[#000066] font-[300]">
                        <h3 class=" mb-10">
                          Matches are made in heaven; memorable weddings are
                          made when you’re Trunnk in Love, and that deserves a
                          celebration!
                        </h3>
                        <ul>
                          <li class="decoration-none">
                            Need flowers in your hair by 4 pm? Will be done!
                          </li>
                          <li>
                            Need to take care of that drunk relative? Leave that
                            on us!
                          </li>
                          <li>
                            Confused deciding a wedding theme? We are here to
                            help.
                          </li>
                          <li>
                            Running on a budget? Who isn’t! Tell us your budget
                            and we’ll work it out.
                          </li>
                          <li className="pt-20">
                            Enjoy your wedding and leave all the work to us.
                          </li>
                        </ul>
                      </div>

                      <div className="py-6">
                        <img
                          className="w-[150px]"
                          src={require("../../assets/img/about/about-us-logo.jpeg")}
                        />
                      </div>
                      <button className="text-white ">
                        <a
                          className=" no-underline hover:text-[#000066] hover:border-2  hover:border-[#000066] bg-[#000066] hover:bg-white hover:rounded-2xl  py-2 px-10 rounded-md"
                          href="/about-us"
                        >
                          {" "}
                          Read More
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- about area end -->

           <!-- service area start --> */}
      <div class="srvice-area mt-40 ">
        <div class="">
          <div className="testimonial-shape-path-image">
            <img
              className="lg:w-[150px] w-[100px] pb-20"
              src={require("../../assets/img/bg/testimonial-bg-1.webp")}
            />
          </div>
          <div className="text-center pb-32">
            <h3 className="text-[45px] text-[#000066]">CHOOSE US</h3>
            <p className="text-[#000066]">Here are 12 reasons why:</p>
            <hr className="w-20 !h-[4px] m-auto !bg-[#000066] !text-[#000066] opacity-100" />
          </div>
          <div class="container">
            <div class="row">
              <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-30 mb-lg-0">
                <div class="lv-service-box text-center">
                  <div class="pb-[30px] rotate-y-hover ">
                    <i className="fa-solid fa-truck text-[#000066] text-[54px] "></i>
                  </div>

                  <div class="lv-service-box-content">
                    <h5 class="lv-service-box-content-title">
                      <a href="#" className="no-underline text-black">
                        Hospitality & Logistics
                      </a>
                    </h5>
                  </div>
                </div>
              </div>
              <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-30 mb-lg-0">
                <div class="lv-service-box text-center">
                  <div class="pb-[30px]  rotate-y-hover">
                    <i class="fa-solid fa-sun text-[#000066] text-[54px] "></i>
                  </div>
                  <div class="lv-service-box-content">
                    <h5 class="lv-service-box-content-title">
                      <a href="#" className="no-underline text-black">
                        Décor, Design, & Floral Management
                      </a>
                    </h5>
                  </div>
                </div>
              </div>
              <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                <div class="lv-service-box text-center no-after">
                  <div class="pb-[30px]  rotate-y-hover">
                    <i class="fa-solid fa-headphones text-[#000066] text-[54px]  "></i>
                  </div>
                  <div class="lv-service-box-content">
                    <h5 class="lv-service-box-content-title">
                      <a href="#" className="no-underline text-black">
                        Entertainment Options
                      </a>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div class="row py-20">
              <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-30 mb-lg-0">
                <div class="lv-service-box text-center">
                  <div class="pb-[30px] rotate-y-hover ">
                    <i className="fa-solid fa-martini-glass text-[#000066] text-[54px] "></i>
                  </div>

                  <div class="lv-service-box-content">
                    <h5 class="lv-service-box-content-title">
                      <a href="#" className="no-underline text-black">
                        Bartending Suggestions
                      </a>
                    </h5>
                  </div>
                </div>
              </div>
              <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-30 mb-lg-0">
                <div class="lv-service-box text-center">
                  <div class="pb-[30px]  rotate-y-hover">
                    <i class="fa-solid fa-gift text-[#000066] text-[54px] "></i>
                  </div>
                  <div class="lv-service-box-content">
                    <h5 class="lv-service-box-content-title">
                      <a href="#" className="no-underline text-black">
                        Welcome Packs, Gifting, & Giveaways
                      </a>
                    </h5>
                  </div>
                </div>
              </div>
              <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                <div class="lv-service-box text-center no-after">
                  <div class="pb-[30px]  rotate-y-hover">
                    <i class="fa-solid fa-plane text-[#000066] text-[54px]  "></i>
                  </div>
                  <div class="lv-service-box-content">
                    <h5 class="lv-service-box-content-title">
                      <a href="#" className="no-underline text-black">
                        Honeymoon Travel, etc
                      </a>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div class="row ">
              <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-30 mb-lg-0">
                <div class="lv-service-box text-center">
                  <div class="pb-[30px] rotate-y-hover ">
                    <i className="fa-solid fa-location-dot text-[#000066] text-[54px] "></i>
                  </div>

                  <div class="lv-service-box-content">
                    <h5 class="lv-service-box-content-title">
                      <a href="#" className="no-underline text-black">
                        Venue Suggestions
                      </a>
                    </h5>
                  </div>
                </div>
              </div>
              <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-30 mb-lg-0">
                <div class="lv-service-box text-center">
                  <div class="pb-[30px]  rotate-y-hover">
                    <i class="fa-brands fa-instagram text-[#000066] text-[54px] "></i>
                  </div>
                  <div class="lv-service-box-content">
                    <h5 class="lv-service-box-content-title">
                      <a href="#" className="no-underline text-black">
                        Social Media Management
                      </a>
                    </h5>
                  </div>
                </div>
              </div>
              <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                <div class="lv-service-box text-center no-after">
                  <div class="pb-[30px]  rotate-y-hover">
                    <i class="fa-solid fa-camera text-[#000066] text-[54px]  "></i>
                  </div>
                  <div class="lv-service-box-content">
                    <h5 class="lv-service-box-content-title">
                      <a href="#" className="no-underline text-black">
                        Photography & Videography
                      </a>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div class="row py-20">
              <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-30 mb-lg-0">
                <div class="lv-service-box text-center">
                  <div class="pb-[30px] rotate-y-hover ">
                    <i className="fa-solid fa-utensils text-[#000066] text-[54px] "></i>
                  </div>

                  <div class="lv-service-box-content">
                    <h5 class="lv-service-box-content-title">
                      <a href="#" className="no-underline text-black">
                        Catering Suggestions
                      </a>
                    </h5>
                  </div>
                </div>
              </div>
              <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-30 mb-lg-0">
                <div class="lv-service-box text-center">
                  <div class="pb-[30px]  rotate-y-hover">
                    <i class="fa-solid fa-paintbrush text-[#000066] text-[54px] "></i>
                  </div>
                  <div class="lv-service-box-content">
                    <h5 class="lv-service-box-content-title">
                      <a href="#" className="no-underline text-black">
                        Invitation Designing
                      </a>
                    </h5>
                  </div>
                </div>
              </div>
              <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                <div class="lv-service-box text-center no-after">
                  <div class="pb-[30px]  rotate-y-hover">
                    <i class="fa-solid fa-heart text-[#000066] text-[54px]  "></i>
                  </div>
                  <div class="lv-service-box-content">
                    <h5 class="lv-service-box-content-title">
                      <a href="#" className="no-underline text-black">
                        Digital Weddings
                      </a>
                    </h5>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-center ">
              <button className="text-white hover:border-2 hoverborder-red-800">
                <a
                  className=" no-underline hover:text-[#000066] border-2  hover:border-[#000066] bg-[#000066] hover:bg-white hover:rounded-2xl  py-2 px-10 rounded-md"
                  href="/services"
                >
                  {" "}
                  View More
                </a>
              </button>
            </div>
          </div>
          <div className="container p-rel">
            <div class="testimonial-shape-path-image-wrap">
              <div class="testimonial-shape-path-image">
                <img
                  className="-rotate-180 "
                  srcSet={require("../../assets/img/bg/testimonial-bg-1.webp")}
                  alt="img not found"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- service area end -->
           <!-- section title start --> */}
      <div className="z-0 mt-[10rem]">
        <BlogSlider />
      </div>

      <div
        class="testimonial-area testimonial-bg-settings pt-[60px] mb-[200px] testimonial-area-spacing"
        data-background="assets/img/bg/testimonial-bg.png"
      >
        <div className="testimonial-shape-path-image">
          <img
            className="lg:w-[150px] w-[100px]"
            src={require("../../assets/img/bg/testimonial-bg-1.webp")}
          />
        </div>
        <div class="container p-rel">
          <div class="testimonial-shape-path-image-wrap mb-20">
            <div class="testimonial-shape-path-image">
              <img
                className="-rotate-180"
                srcSet={require("../../assets/img/bg/testimonial-bg-1.webp")}
                alt="img not found"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-xxl-12">
              <div class="lv-section-title-wrap text-center px-3 pb-24">
                <h2 class="lv-section-title !text-[45px] text-[#000066]">
                  Testimonials
                </h2>
                <p class="mb-0 text-[#000066]">
                  Couple's happiness is the best reward for us & these fill our
                  hearts of joy.
                </p>
              </div>
            </div>
          </div>
          <Slider autoplay autoplaySpeed={3000} infinite={true} {...settings}>
            {/* First Testimonial Slide */}
            <div className="">
              <div className="lv-testimonial-box lg:mx-36 text-[#000066] ">
                <div className="lv-testimonial-content lg:mb-25">
                  <p>
                    We had Trunnk in Love for our event & wedding planning. From
                    logistics, RSVP, on-ground management, to hospitality, they
                    were here for us for everything. Even when we needed vidhi
                    items in the middle of the night they got it for us. Kudos
                    to the team. Cheers & thank you!
                  </p>
                </div>
                <div className="lv-testimonial-author-box text-center">
                  <h5 className="lv-testimonial-author-name text-[#000066]">
                    Rahul & Namrata{" "}
                  </h5>
                  <span className="lv-testimonial-author-date">
                    December 2022
                  </span>
                </div>
              </div>
            </div>
            {/* second Testimonial Slide */}
            <div className="">
              <div className="lv-testimonial-box lg:mx-36 text-[#000066] ">
                <div className="lv-testimonial-content mb-25">
                  <p>
                    We love Trunnk In Love so so much, Thank you so much for
                    making our wedding such a dream. Working so hard tirelessly
                    answering all those late-night calls from me across America.
                    I appreciate it. We wouldn’t have been able to do all of
                    this without you all so this has been one hell of an
                    experience thanks to all of you thank you.
                  </p>
                </div>
                <div className="lv-testimonial-author-box text-center">
                  <h5 className="lv-testimonial-author-name text-[#000066]">
                    Pondharshini{" "}
                  </h5>
                  <span className="lv-testimonial-author-date">
                    January 2024
                  </span>
                </div>
              </div>
            </div>
            {/* Thired Testimonial Slide */}
            <div className="">
              <div className="lv-testimonial-box lg:mx-36 text-[#000066]">
                <div className="lv-testimonial-content mb-25">
                  <p>
                    All I wanna say is that the team has been so helpful they
                    have been so resourceful for every event We’ve been like my
                    family members in terms of communication in terms of
                    transparency attention to detail not just being there being
                    alert being responsive to our requests working with them has
                    been effortless we’re happy to be a part of the Trunnk in
                    Love family
                  </p>
                </div>
                <div className="lv-testimonial-author-box text-center">
                  <h5 className="lv-testimonial-author-name text-[#000066]">
                    Sarvesh{" "}
                  </h5>
                  <span className="lv-testimonial-author-date">
                    January 2024
                  </span>
                </div>
              </div>
            </div>
            {/* Forth Testimonial Slide */}
            <div className="">
              <div className="lv-testimonial-box lg:mx-36 text-[#000066]">
                <div className="lv-testimonial-content mb-25">
                  <p>
                    You people are so great you guys did an amazing job I really
                    love you guys she’s been very appreciative of the amount of
                    support you gave my daughter you always been on the phone
                    with her all the time and she appreciates all those shadows
                    that you guys have for her I really recommend you to our
                    friends
                  </p>
                </div>
                <div className="lv-testimonial-author-box text-center">
                  <h5 className="lv-testimonial-author-name text-[#000066]">
                    Pondarshini Parent’s{" "}
                  </h5>
                  <span className="lv-testimonial-author-date">
                    January 2024
                  </span>
                </div>
              </div>
            </div>

            {/* Fifth Testimonial Slide */}
            <div className="">
              <div className="lv-testimonial-box lg:mx-36 text-[#000066]">
                <div className="lv-testimonial-content mb-25">
                  <p>
                    We really want to thank the TIL team for making this special
                    occasion such a great success for both of us yeah they have
                    been really helpful in looking out for everything and they
                    have always been there for us just one call and they were
                    there so we are really happy with them made my life easier
                    for these two days so thank you they have been like a
                    blessing to us from the start
                  </p>
                </div>
                <div className="lv-testimonial-author-box text-center">
                  <h5 className="lv-testimonial-author-name text-[#000066]">
                    Aniket & Amruta{" "}
                  </h5>
                  <span className="lv-testimonial-author-date">
                    January 2024
                  </span>
                </div>
              </div>
            </div>
            {/* Fifth Testimonial Slide */}
            <div className="">
              <div className="lv-testimonial-box lg:mx-36 text-[#000066]">
                <div className="lv-testimonial-content mb-25">
                  <p>
                    We want to say a big thank you to Trunnk in Love & their
                    team for making our day special, they fulfilled all our
                    last-minute requirements anything that we asked for was
                    taken care of & they managed everything with complete ease
                  </p>
                </div>
                <div className="lv-testimonial-author-box text-center">
                  <h5 className="lv-testimonial-author-name text-[#000066]">
                    Ananya & Rohan{" "}
                  </h5>
                  <span className="lv-testimonial-author-date">
                    December 2022
                  </span>
                </div>
              </div>
            </div>
            {/* Sixth Testimonial Slide */}
            <div className="">
              <div className="lv-testimonial-box lg:mx-36 text-[#000066]">
                <div className="lv-testimonial-content mb-25">
                  <p>
                    Thank you Trunnk In Love! Thank you, Akshaay & your team you
                    made our work, easier & we were able to enjoy the
                    celebrations to the fullest.
                  </p>
                </div>
                <div className="lv-testimonial-author-box text-center">
                  <h5 className="lv-testimonial-author-name text-[#000066]">
                    Smita Sharma{" "}
                  </h5>
                  {/* <span className="lv-testimonial-author-date">
                      December 2022
                    </span> */}
                </div>
              </div>
            </div>
            {/* 7th Testimonial Slide */}
            <div className="">
              <div className="lv-testimonial-box lg:mx-36 text-[#000066]">
                <div className="lv-testimonial-content mb-25">
                  <p>
                    I highly recommend Trunnk in Love to everyone, a completely
                    efficient team from start to end, and they are a play
                    solution for all your needs to make your special day more
                    special.
                  </p>
                </div>
                <div className="lv-testimonial-author-box text-center">
                  <h5 className="lv-testimonial-author-name text-[#000066]">
                    Anil Sharma{" "}
                  </h5>
                  {/* <span className="lv-testimonial-author-date">
                      December 2022
                    </span> */}
                </div>
              </div>
            </div>
            {/* 8th Testimonial Slide */}
            <div className="">
              <div className="lv-testimonial-box lg:mx-36 text-[#000066]">
                <div className="lv-testimonial-content mb-25">
                  <p>
                    It was a great time with Trunnk In Love, we had good support
                    and the whole team was very cooperative and professional, al
                    the best for the future Thank you!
                  </p>
                </div>
                <div className="lv-testimonial-author-box text-center">
                  <h5 className="lv-testimonial-author-name text-[#000066]">
                    Prakash Tarachandani{" "}
                  </h5>
                  <span className="lv-testimonial-author-date">
                    December 2022
                  </span>
                </div>
              </div>
            </div>
            {/* 9th Testimonial Slide */}
            <div className="">
              <div className="lv-testimonial-box lg:mx-36 text-[#000066]">
                <div className="lv-testimonial-content mb-25">
                  <p>
                    We were recommended Trunnk In Love by our close friend when
                    we met with Akshaay we knew we defiantly wanted Trunnk in
                    Love as my brother’s wedding planner, I cannot be more
                    thankful enough to the entire team who came together and
                    helped us with all our requirements & being full-on support.
                  </p>
                </div>
                <div className="lv-testimonial-author-box text-center">
                  <h5 className="lv-testimonial-author-name text-[#000066]">
                    Apurv Sharma{" "}
                  </h5>
                  <span className="lv-testimonial-author-date">
                    December 2022
                  </span>
                </div>
              </div>
            </div>
            {/* 10th Testimonial Slide */}
            <div className="">
              <div className="lv-testimonial-box lg:mx-36 text-[#000066]">
                <div className="lv-testimonial-content mb-25">
                  <p>
                    We like to service of Trunnk in Love. It was excellent. They
                    are all helpful people. We enjoyed the service. We like the
                    service very much. Thanks to Akshaay and the full group of
                    TIL
                  </p>
                </div>
                <div className="lv-testimonial-author-box text-center">
                  <h5 className="lv-testimonial-author-name text-[#000066]">
                    Aniket’s Parents{" "}
                  </h5>
                  <span className="lv-testimonial-author-date">Jan 2024</span>
                </div>
              </div>
            </div>

            {/* Add more slides as needed */}
          </Slider>
        </div>
      </div>
      {/* Blog start */}
      <div class="blog-area pt-160 p-rel pb-95">
        <div class="lv-blog-shape-wrap">
          <div class="lv-blog-shape-img-1">
            <img srcSet={require("../../assets/img/bg/blog-bg.webp")} alt="" />
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-xxl-12">
              <div class="lv-section-title-wrap pb-65 text-center">
                <h2 class="lv-section-title !text-[45px] text-[#000066]">
                  Read Our Blog
                </h2>
                <p class="mb-0 text-[#000066]">
                  {" "}
                  Coast to coast and beyond the over celebration sweet news
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-30 mb-lg-0">
              <div class="lv-blog-box">
                <a className="no-underline text-black" href="blog-details.html">
                  <div class="fix">
                    <div
                      class="lv-blog-box-img bg-default"
                      style={blogbg1}
                    ></div>
                  </div>
                </a>
                <div class="lv-blog-box-content">
                  <div class="lv-blog-box-meta">
                    <span class="lv-blog-box-meta-date">25/02/2022</span>
                    <a
                      className="no-underline text-black lv-blog-box-meta-cat"
                      href="portfolio-3.html"
                    >
                      Photography
                    </a>
                  </div>
                  <h5 class="lv-blog-box-title">
                    <a
                      className="no-underline text-black"
                      href="/guide-to-a-sustainable-wedding"
                    >
                      GUIDE TO A SUSTAINABLE WEDDING
                    </a>
                  </h5>
                  <a
                    href="/guide-to-a-sustainable-wedding"
                    className="no-underline text-black lv-blog-box-read-btn"
                  >
                    Read More
                  </a>
                </div>
              </div>
            </div>
            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-30 mb-lg-0">
              <div class="lv-blog-box">
                <a className="no-underline text-black" href="blog-details.html">
                  <div class="fix">
                    <div
                      class="lv-blog-box-img bg-default"
                      style={blogbg3}
                    ></div>
                  </div>
                </a>
                <div class="lv-blog-box-content">
                  <div class="lv-blog-box-meta">
                    <span class="lv-blog-box-meta-date">22/02/2022</span>
                    <a
                      href="portfolio-3.html"
                      className="no-underline text-black  lv-blog-box-meta-cat"
                    >
                      Wedding
                    </a>
                  </div>
                  <h5 class="lv-blog-box-title">
                    <a
                      className="no-underline text-black"
                      href="/destination-weddings-dos-and-donts"
                    >
                      DESTINATION WEDDINGS DO’S & DON’TS
                    </a>
                  </h5>
                  <a
                    href="/destination-weddings-dos-and-donts"
                    className="no-underline text-black lv-blog-box-read-btn"
                  >
                    Read More
                  </a>
                </div>
              </div>
            </div>
            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-30 mb-lg-0">
              <div class="lv-blog-box">
                <a href="blog-details.html">
                  <div class="fix">
                    <div
                      class="lv-blog-box-img bg-default"
                      style={blogbg2}
                    ></div>
                  </div>
                </a>
                <div class="lv-blog-box-content">
                  <div class="lv-blog-box-meta">
                    <span class="lv-blog-box-meta-date">13/01/2022</span>
                    <a
                      href="portfolio-3.html"
                      class=" no-underline text-black  lv-blog-box-meta-cat"
                    >
                      Party
                    </a>
                  </div>
                  <h5 class="lv-blog-box-title ">
                    <a
                      className="no-underline text-black "
                      href="/reasons-you-should-hire-a-wedding-planner"
                    >
                      REASONS YOU SHOULD HIRE A WEDDING PLANNER
                    </a>
                  </h5>
                  <a
                    href="/reasons-you-should-hire-a-wedding-planner"
                    class="lv-blog-box-read-btn"
                  >
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Blog end */}
      <div
        class="cta-area bg-default pt-110 pb-115 lv-bg-opacity lv-opacity-5"
        style={bgcta}
      >
        <div class="container">
          <div class="lv-section-title-wrap text-center">
            <h2 class="lv-section-title text-white mb-45 !text-[45px] ">
              Say 'Yes' to a perfect wedding, <br />
              Begin your wedding journey today with us.
            </h2>
            <div
              onClick={() => setIsPopupOpen(!isPopupOpen)}
              className=" lv-transparent-btn cursor-pointer"
            >
              <span class="lv-transparent-horizontal-line"></span>
              <span class="lv-transparent-vertical-line"></span>
              <span class="lv-transparent-btn-text">Book Now</span>
            </div>
          </div>
        </div>
      </div>
      {/* PopUp Form Start  */}
      {isPopupOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-10">
          <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
            <div className="flex justify-between items-center">
              <h3 className="text-lg font-medium text-gray-900">
                Book Your Appoinment
              </h3>
              <button
                onClick={() => setIsPopupOpen(false)}
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
              >
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div>
                <label
                  htmlFor="name"
                  className="text-sm font-medium text-gray-900 block mb-2"
                >
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="text-sm font-medium text-gray-900 block mb-2"
                >
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="mobile"
                  className="text-sm font-medium text-gray-900 block mb-2"
                >
                  Mobile
                </label>
                <input
                  type="tel"
                  name="mobile"
                  id="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                />
              </div>
              <button
                type="submit"
                className="w-full text-white bg-blue-500 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
